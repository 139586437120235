import React from "react";
import { Link } from "react-router-dom";

const NavigationBar = () => {
  return (
    <nav className="grid h-20 grid-cols-1 flex-col items-center justify-center md:h-24">
      <div
        className={` flex justify-between pt-4 text-white h-20 text-2xl font-mono font-bold opacity-100 dark:text-white md:flex lg:space-x-20`}
      >
        <span className={`hover:underline`}>
          <Link to="/">Home</Link>
        </span>
        <span className={`hover:underline`}>
          <Link to="/about">About</Link>
        </span>
        <span className={`hover:underline inline-block text-nowrap`}>
          <Link to="/contact">Contact Us</Link>
        </span>
        <span className={`hover:underline`}>
          <Link to="/gallery">Gallery</Link>
        </span>
        <span className={`hover:underline`}>
          <Link to="https://give.gracetabernacleinternational.com">Give</Link>
        </span>
      </div>
    </nav>
  );
};

export default NavigationBar;
