import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import Carousel from "../components/Carousel";

  const images = [
    'carouselImage1.jpeg',
    'carouselImage2.jpeg',
    'carouselImage4.jpeg',
  ];

const Home = () => {
  return (
    <Layout page={"Home"}>
        <>
          <div className="">
            <Carousel images={images} />
          </div>
        </>
    </Layout>
  );
};

export default Home;
