import React, { useState } from "react"
import Layout from "../components/Layout"
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/20/solid'

const imageList = []

for (let i = 1 ; i < 25; i++) {
    imageList.push(`assets/picture${i}.jpeg`)
}

const Gallery = () =>{
    const [isModelOn, setIsModelOn] = useState(false)
    const [currentImage, setCurrentImage] = useState('')

    return(
        <Layout page={'Gallery'}>
            <Dialog open={isModelOn} onClose={() => setIsModelOn(false)} className="fixed z-10 inset-0 bg-gray-700 bg-opacity-20" initialFocus={undefined}>
				<div className="fixed inset-0 w-full">
					<div className="flex min-h-full items-center justify-center md:px-20 text-center w-full">
						<DialogPanel className="md:w-full transform overflow-hidden max-h-screen m-2 overflow-y-visible rounded-2xl bg-white p-6 align-middle shadow-xl transition-all border">
							<DialogTitle
								as="h3"
							>
								<div className="flex flex-row justify-end items-end">
									<button type="button" onClick={() => setIsModelOn(false)}>
										<XMarkIcon className="h-8 w-8 text-gray-400 hover:text-gray-600 mr-2 hover:cursor-pointer" aria-hidden="true" />
									</button>
								</div>
								<button type="button" onClick={() => setIsModelOn(false)} aria-label="Close" />
							</DialogTitle>
                            <div className="flex flex-col items-center justify-center">
                                <img
                                    key={currentImage}
                                    src={currentImage}
                                    alt="praying woman"
                                    className="rounded-xl h-96 md:h-[40rem] my-4"
                                />
                            </div>
						</DialogPanel>
					</div>
				</div>
			</Dialog>
            <div className="grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-4">
                {
                    imageList.map((item, index) => (
                        <img
                            key={index}
                            src={item}
                            alt="praying woman"
                            className="rounded-xl"
                            onClick={(event) => {
                                setCurrentImage(event.target.src);
                                setIsModelOn(true)
                            }}
                        />
                    ))
                }
            </div>
        </Layout>
       
    )
}

export default Gallery