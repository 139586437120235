import React from "react";

const EmailUs = () => {
  const email = "gracetabernacleint21@gmail.com";
  const subject = "[Reason for writing]";
  const body = "Hello my name is [name].\nI'm writing to...";

  const handleApplyClick = () => {
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink);
  };

  return (
    <button
      onClick={handleApplyClick}
      className="h-20 w-68 rounded-xl border-8 px-2 border-white font-sans text-base text-wrap font-semibold text-white hover:bg-white hover:text-blue-800"
    >
      Click here to email us @gracetabernacleint21@gmail.com
    </button>
  );
};

export default EmailUs;
