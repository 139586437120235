import React from "react";
import { Link } from "react-router-dom";

const DropDownMenu = () => {
  return (
    <nav className="grid h-20 grid-cols-1 flex-col items-center justify-center md:h-24 z-50">
      <details className="inline-block text-white h-20 text-2xl font-mono font-bold opacity-100 dark:text-white md:flex">
        <summary className="cursor-pointer">☰</summary>

        <div className="flex flex-col space-y-2 p-2 bg-blue-400 rounded-lg">
          <span className="hover:underline">
            <Link to="/">Home</Link>
          </span>
          <span className="hover:underline">
            <Link to="/about">About</Link>
          </span>
          <span className="hover:underline">
            <Link to="/contact">Contact us</Link>
          </span>
          <span className={`hover:underline`}>
          <Link to="/gallery">Gallery</Link>
        </span>
          <span className="hover:underline">
            <Link to="https://give.gracetabernacleinternational.com/">Give</Link>
          </span>
        </div>
      </details>
    </nav>
  );
};

export default DropDownMenu;
