import { Link } from 'react-router-dom';
const LocationButton = ()=>{
    return (
        <div
        className="md: rounded-xl border-8 md:mr-48 px-2 py-4 border-white font-sans text-base font-semibold text-white hover:bg-white hover:text-blue-800"
        >
            <a 
    href="https://www.google.co.uk/maps/place/The+Bowman+Centre/@51.1632055,-1.7671742,16.72z/data=!4m6!3m5!1s0x4873ef6174fc9993:0xd984a93288435edf!8m2!3d51.163442!4d-1.7646602!16s%2Fg%2F11bzsz7m1z?entry=ttu" 
    target="_blank"  
    rel="noreferrer"
>    
    Find us every Sunday at Shears Dr, Amesbury, Salisbury SP4 7XT
</a>
        </div>
        
    )
}

export default LocationButton;