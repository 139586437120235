import React from "react";
import Layout from "../components/Layout";
import EmailUs from "../components/EmailUs";
import LocationButton from "../components/LocationButton";

const Contact = () => {
  return (
    <Layout page="Contact us">
        <div className="text-left text-white font-mono">
            <h1 className="text-3xl pb-4 font-bold">Where we are</h1>
            <div className="pb-8">
                <LocationButton />
            </div>
            <h1 className="text-3xl pb-4 font-bold">Tell us about you</h1>
            <div className="pb-8"> 
                <button 
                    href={'/'} 
                    target="_blank"  
                    rel="noreferrer"
                    className="h-20 w-68 rounded-xl border-8 px-2 border-white font-sans text-base font-semibold text-white hover:bg-white hover:text-blue-800"
                >
                    <a href="https://forms.gle/5oV9ZV8sJw2Y2tYz9" target="_blank" rel="noreferrer">
                        Click here to fill out a google form
                    </a>
                </button>
            </div>
            
            <div className="pb-8">
                <h1 className="text-3xl pb-4 font-bold">Call us at:</h1>
                <p>07541138860</p>
            </div>

            

            <EmailUs />
        </div>
    </Layout>
  );
};

export default Contact;
