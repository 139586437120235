import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        }, 10000);
        
        return () => clearInterval(timer);
    }, [images.length]);

    const handleRadioChange = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="relative  flex flex-col items-center justify-center h-40 md:h-96">
            {images.map((image, index) => (
                <div className='absolute items-center'>
                     <motion.img
                    key={index}
                    src={image}
                    alt={`carousel ${index}`}
                    className={`relative top-0 w-full h-96 object-cover ${
                    index === currentIndex ? 'opacity-100' : 'opacity-0'
                    }`}
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: index === currentIndex ? 1.05 : 0.95, opacity: index === currentIndex ? 1 : 0 }}
                    transition={{ duration: 1.5 }}
                    style={{ zIndex: 1 }}
                />
                </div>
               
            ))}

            <div className="absolute  pt-96 top-4 z-50 flex justify-center space-x-2">
                {images.map((_, index) => (
                    <label key={index} className={`w-4 h-4 rounded-full cursor-pointer ${
                    index === currentIndex ? 'bg-white' : 'bg-gray-600'
                    }`}>
                        <input
                            type="radio"
                            name="carousel-radio"
                            className="opacity-0 absolute"
                            checked={index === currentIndex}
                            onChange={() => handleRadioChange(index)}
                        />
                    </label>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
