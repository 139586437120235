import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = (props) => {
  return (
    <>
      
      <div>
        <Header slug={props.page} />
        <div className="min-h-screen w-full items-center justify-center bg-gradient-to-b from-[#2e026d] to-[#15162c] px-4 md:px-20">
          <div className="pt-12">{props.children}</div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
