import React from "react";
import Layout from "../components/Layout";
const About = () => {
  return (
    <Layout page="About">
      <div className="text-white font-mono text-left">
        <h1 className="text-3xl pb-4 font-bold">The Mission</h1>
        <p>
          To preach the Gospel, heal broken hearts, proclaim liberty to the
          captives, recover blind sight, Open prison doors to those who are
          bound. (Isa 61:1, Luke 4:18)
        </p>
        <h1 className="text-3xl py-4 font-bold">Our Vision</h1>
        <p>
          To raise disciples for Jesus Christ through teaching, equipping and
          demonstration of His power.
        </p>
        <h1 className="text-3xl py-4 font-bold">Statement of Belief</h1>
        <p>
          We believe that bible is the complete inspired word of God (2Tim3:16,
          2Pet 1:20-21)
        </p>
        <p>
          We believe that Our God is one manifested in three person, The
          Father, the Son and the Holy Spirit. (1John5:7, Ephesians 4:5-6,
          Gen. 1:26).
        </p>
        <p>We believe in the deity of Christ. (John 1: 1-4; John 10:30; Hebrews 1:1-5).</p>
        <div className="pl-4">
          <p>• He was born of a virgin (Matthew 1:18-25; Luke 1:30-35)</p>
          <p>• He was conceived of the Holy Ghost (Matthew 1:18-25, Luke 1:30-35)</p>
          <p>• He died (John 19:30-35)</p>
          <p>• He rose again from the dead bodily (John 20:25-29, Luke 24:36-40)</p>
          <p>• He rose again from the dead bodily (John 20:25-29, Luke 24:36-40)</p>
        </div>
        <p>We believe that the church will be ruptured and in the Second coming of Our Lord Jesus Christ.(1 Thessalonians 4:16-17; Matthew 29-30)</p>
        <p>We believe that the only means of being cleansed from sin is repentance and faith in the precious blood of Jesus (Acts 3:19, Luke 24:47; Ephesians 1:7).</p>
        <p>We believe regeneration by the power of the Holy Spirit through the Word of God is essential for personal salvation (Titus 2:5, John 3:3-5, Ephesians 5:25-27).</p>
        <p>We believe that the redemptive work of Christ on the cross provides divine healing for the body, salvation for the soul of everyone that believes. (1 Peter 2: 24; Acts 3: 16, Acts 9: 32 -35).</p>
        <p>We believe that when an individual receives the Holy Ghost, he receives divine enablement for Christian service and witness. (Acts 1: 8; 2:4; 3:1 -26; 4:5 – 12).</p>
        <p>We believe in the sanctifying power of the Holy Ghost. (Romans 15: 16; 1 Corinthians 6:11).</p>
        <p>We believe in the final resurrection of both the saved and the lost; the former to eternal life and the latter to eternal judgement. (Revelations 20: 11 -15; 1 Corinthians 15: 12 – 23)</p>
      </div>
    </Layout>
  );
};

export default About;
